import { Component } from '@angular/core';
import {AuthService} from './shared/services/auth/auth.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {translations} from './i18n/de.translations';
import {Actions, ofActionDispatched} from '@ngxs/store';
import {Logout} from './modules/login/state/login.actions';
import {AnimationOptions} from 'ngx-lottie';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  lottieOptions: AnimationOptions = {
    path: '/assets/lottie/error.json'
  };

  constructor(private router: Router,
              private actions: Actions,
              private toastrService: ToastrService,
              private translateService: TranslateService,
              public authService: AuthService) {
    this.translateService.setTranslation('de', translations);
    this.translateService.setDefaultLang('de');
    this.translateService.use('de');
    this.actions.pipe(ofActionDispatched(Logout)).subscribe(() => {
      this.router.navigateByUrl('/login');
    });
  }
  title = 'frontend-admintool';
}
