import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CategoryInterface} from '../../../interfaces/category.interface';
import {ItemBtnInterface} from './item-btn.interface';

@Component({
  selector: 'app-item-btn',
  templateUrl: './item-btn.component.html',
  styleUrls: ['./item-btn.component.scss']
})
export class ItemBtnComponent implements OnInit {

  @Input()
  item: ItemBtnInterface;
  @Input()
  hexCode: string;
  @Input()
  icon: string;
  @Input()
  activeItem: string;

  @Output()
  buttonClicked: EventEmitter<ItemBtnInterface> = new EventEmitter<ItemBtnInterface>();

  // variables to style button --> check whether it is hovered or not,
  // found this solution on the internet because did not know how to ask in the view whether it is hovered or not
  // and there is no access to the color variable in the css code (because it is dynamic)
  hover: boolean;


  constructor() {
  }

  clickOverwrite(val: ItemBtnInterface) {
    this.buttonClicked.emit(val);
  }

  ngOnInit(): void {
  }

}
