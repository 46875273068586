import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserInterface } from 'src/app/interfaces/user.interface';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {PaginationInterface} from '../../../util/pagination.interface';
import {ResultPagination} from '../../../util';
import {ROLES} from '../../../modules/users/state/roles.constant';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }

  getUsers(pagination: PaginationInterface, search: string): Observable<ResultPagination<UserInterface>> {
    let httpHeaders = new HttpParams().set('limit', '' + pagination.limit).set('page', '' + pagination.page);
    if (search) {
      httpHeaders = httpHeaders.set('search', search);
    }
    return this.http.get<ResultPagination<UserInterface>>(`${environment.baseUri}/user`, {params: httpHeaders});
  }

  getClerks(pagination: PaginationInterface, search: string): Observable<ResultPagination<UserInterface>> {
    let httpHeaders = new HttpParams().set('limit', '' + pagination.limit).set('page', '' + pagination.page);
    if (search) {
      httpHeaders = httpHeaders.set('search', search);
    }
    httpHeaders = httpHeaders.set('roleId', ROLES.CLERK);
    return this.http.get<ResultPagination<UserInterface>>(`${environment.baseUri}/user`, {params: httpHeaders});
  }

  getUser(id: number): Observable<UserInterface> {
    return this.http.get<UserInterface>(`${environment.baseUri}/user/${id}`);
  }

  changeUserIsBanned(id: number, isBanned: boolean): Observable<any> {
    const body = { isBanned };
    return this.http.put<UserInterface>(`${environment.baseUri}/user/${id}/admin`, body);
  }

  changeRoleId(id: number, roles: number[]): Observable<any> {
    const body = { roleIds: roles };
    return this.http.put<UserInterface>(`${environment.baseUri}/user/${id}/admin`, body);
  }
}
