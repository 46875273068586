import {RoleInterface} from '../../../interfaces/role.interface';

export class LoadUsers {
  static readonly type = '[User] Load users';
  constructor(public body: { search: string, limit: number, page: number }) {
  }
}

export class LoadClerks {
  static readonly type = '[User] Load clerks';
  constructor(public body: { search?: string, limit?: number, page?: number }) {
  }
}

export class ChangeBanStatusUser {
  static readonly type = '[User] Change ban status';
  constructor(public id: number, public isBanned: boolean) {
  }
}

export class ChangeRole {
  static readonly type = '[User] Change role';
  constructor(public id: number, public roleIds: number[]) {
  }
}
