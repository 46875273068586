import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { UserData } from './auth.interface';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) {}

  login(body: { email: string, password: string, withRefreshToken: boolean }): Observable<UserData> {
    return this.http.post<UserData>(`${environment.baseUri}/auth/login`, body);
  }

  // Refresh tokens
  refreshToken(token: string): Observable<UserData> {
    return this.http.post<UserData>(`${environment.baseUri}/auth/refresh`, { refreshToken: token });
  }
}
