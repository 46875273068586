import * as moment from 'moment';

import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, throwError} from 'rxjs';
import {CategoryInterface} from '../../../interfaces/category.interface';
import {StatusInterface} from '../../../interfaces/status.interface';
import {StatusService} from '../../services/status/status.service';
import {CategoryService} from '../../services/category/category.service';
import {NoticeService} from '../../services/notice/notice.service';
import {catchError, tap} from 'rxjs/operators';
import {RangeDateInterface} from '../range-datepicker/range-date.interface';
import {FormatTimeService} from '../../services/format-time.service';
import {LoadClerks} from '../../../modules/users/state/user.actions';
import {Store} from '@ngxs/store';

@Component({
  selector: 'app-csv-modal',
  templateUrl: './csv-modal.component.html',
  styleUrls: ['./csv-modal.component.scss']
})
export class CsvModalComponent implements OnInit {

  categories$: Observable<CategoryInterface[]>;
  status$: Observable<StatusInterface[]>;
  currentStatus: StatusInterface;
  currentCategory: CategoryInterface;
  startDate: string;
  endDate: string;
  archived = false;
  hasError = false;
  clerks: number[];

  constructor(public modal: NgbActiveModal,
              private statusService: StatusService,
              private categoryService: CategoryService,
              private noticeService: NoticeService,
              private timeService: FormatTimeService,
              private store: Store) { }

  ngOnInit(): void {
    this.status$ = this.statusService.getStatus();
    this.categories$ = this.categoryService.getCategories();
    this.store.dispatch(new LoadClerks({search: null, limit: 100, page: 1}));
  }

  handleChangeStatus(status) {
    this.currentStatus = status;
  }

  handleChangeCategory(category) {
    this.currentCategory = category;
  }

  handleUpdateDates(dates: RangeDateInterface) {
    const { startDate, endDate, hasError } = this.timeService.formatRangeDate(dates)
    this.hasError = hasError
    this.startDate = startDate
    this.endDate = endDate
  }

  handleChangeClerks(clerks: number[]) {
    this.clerks = clerks;
  }

  exportCSV() {
    this.modal.close(true);
    this.noticeService.getCSV(
      this.startDate,
      this.endDate || moment().add(1, 'day').format('YYYY-MM-DD'),
      this.currentCategory?.id,
      this.currentStatus?.id,
      this.archived,
      (this.clerks || []).join(',')
    ).pipe(
      tap((x) => {
        const formattedStartDate = this.startDate ? moment(this.startDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
        const formattedEndDate = this.endDate ? moment(this.endDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '';
        const fileName = `meldungexport${formattedStartDate ? formattedStartDate : ''}${formattedEndDate ? '-' + formattedEndDate : ''}.xlsx`;
        // @ts-ignore
        const newBlob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        // maybe workaround solution
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.

        const data = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = data;
        link.download = fileName;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
        /*
        const url = window.URL.createObjectURL(blob);
        window.open(url);
         */
      }),
      catchError(err => {
        return throwError(err);
      })
    ).subscribe();
  }

}
