import { Injectable } from '@angular/core';
import {LoaderService} from './loader.service';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor{

  constructor(public loaderService: LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // set the loader to true when the http call has started
    this.loaderService.isLoading.next(true);

    // the next handler has to be returned to complete the call
    return next.handle(req).pipe(
      // it has to be piped to check whether the call has been completed
      finalize(() => {
        // no matter the call was successful or threw errors, the loader has to be stopped
        this.loaderService.isLoading.next(false);
      })
    );
  }
}
