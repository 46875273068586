import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import {ImageDirective} from './directives/image.directive';
import { ModalComponent } from './components/modal/modal.component';
import {BackButtonComponent} from './components/back-button/back-button.component';
import {StatusHistoryComponent} from './components/status-history/status-history.component';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { LottieAlertComponent } from './components/lottie-alert/lottie-alert.component';
import {LottieModule} from 'ngx-lottie';
import { PaginationComponent } from './components/pagination/pagination.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ItemSelectComponent} from './components/item-select/item-select.component';
import { ItemBtnComponent } from './components/item-btn/item-btn.component';
import { RangeDatepickerComponent } from './components/range-datepicker/range-datepicker.component';
import { BadgeComponent } from './components/badge/badge.component';
import { ClerkDropdownComponent } from './components/clerk-dropdown/clerk-dropdown.component';
import {NgSelectModule} from '@ng-select/ng-select';
import { SwitchComponent } from './components/switch/switch.component';

@NgModule({
    declarations: [
        IconComponent,
        ImageDirective,
        ModalComponent,
        BackButtonComponent,
        ItemSelectComponent,
        LottieAlertComponent,
        PaginationComponent,
        ItemBtnComponent,
        RangeDatepickerComponent,
        BadgeComponent,
        ClerkDropdownComponent,
        SwitchComponent
    ],
  providers: [
    NgbActiveModal
  ],
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule.forChild(),
    FontAwesomeModule,
    LottieModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  exports: [
    IconComponent,
    ImageDirective,
    BackButtonComponent,
    ModalComponent,
    ItemSelectComponent,
    PaginationComponent,
    ItemBtnComponent,
    RangeDatepickerComponent,
    BadgeComponent,
    ClerkDropdownComponent,
    SwitchComponent
  ]
})
export class UniversalModule { }
