import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CategoryInterface, UpdateCategoryInterface } from '../../../interfaces/category.interface';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  constructor(private http: HttpClient) { }

  getCategories(): Observable<CategoryInterface[]> {
    return this.http.get<{ result: CategoryInterface[] }>(`${environment.baseUri}/category?withInternal=yes`).pipe(map((erg) => {
      return erg.result;
    }));
  }

  getCategory(id: number): Observable<CategoryInterface> {
    return this.http.get<CategoryInterface>(`${environment.baseUri}/category/${id}`);
  }

  addCategory(body: { name: string, hexCode: string, icon: string, active: boolean }): Observable<CategoryInterface> {
    return this.http.post<CategoryInterface>(`${environment.baseUri}/category`, body);
  }

  updateCategoryActiveStatus(id: number, isActive: boolean): Observable<CategoryInterface> {
    // toggle active / inactive
    const body = {active: !isActive};
    return this.http.put<CategoryInterface>(`${environment.baseUri}/category/${id}`, body);
  }

  updateCategory(id: number, body: UpdateCategoryInterface) {
    return this.http.put<CategoryInterface>(`${environment.baseUri}/category/${id}`, body);
  }
}


/*
     Testdaten
     const categories: CategoryInterface[] = [
       {
         id: 1,
         name: 'Beleuchtung',
         icon: 'bulb',
         hexCode: '#d19d00',
         active: true
       },
       {
         id: 2,
         name: 'Schlagloch',
         icon: 'car',
         hexCode: '#b91313',
         active: true
       },
       {
         id: 3,
         name: 'Kanalgebrechen',
         icon: 'grid',
         hexCode: '#2a5898',
         active: true
       },
       {
         id: 4,
         name: 'Verunreinigung',
         icon: 'trash',
         hexCode: '#128716',
         active: true
       },
       {
         id: 5,
         name: 'Sonstiges',
         icon: 'clipboard',
         hexCode: '#590099',
         active: true
       }
     ];
   return of(categories) as Observable<CategoryInterface[]>;
    */
