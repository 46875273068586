import { Component, OnInit } from '@angular/core';
import {
  faChartPie,
  faCog,
  faPersonBooth,
  faNewspaper,
  faMapMarkedAlt,
  faList,
  faShapes,
  faFileDownload,
  faUsers,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CsvModalComponent} from '../../shared/components/csv-modal/csv-modal.component';
import { AuthService } from '../../shared/services/auth/auth.service';
import { Observable } from 'rxjs';
import {LoaderService} from '../../shared/services/loader/loader.service';
import {Select, Store} from '@ngxs/store';
import {AuthState} from '../login/state/login.state';
import {Logout} from '../login/state/login.actions';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  faAdd = faPlus;
  faCog = faCog;
  faMapMarkedAlt = faMapMarkedAlt;
  faList = faList;
  faUsers = faUsers;
  personOutline = faPersonBooth;
  faChart = faChartPie;
  faShapes = faShapes;
  faExport = faFileDownload;
  faNewspaper = faNewspaper;
  // enabled Neuigkeiten & Auswertungen
  isEnabled = false;
  public isMenuCollapsed = true;

  @Select(AuthState.isLoggedIn)
  loggedIn: Observable<boolean>;

  @Select(AuthState.isEmployee)
  isEmployee$: Observable<boolean>;

  constructor(public modal: NgbModal,
              private store: Store,
              public authService: AuthService,
              public loaderService: LoaderService) {
  }

  ngOnInit(): void {
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  openCSVModal() {
    this.modal.open(CsvModalComponent);
  }

}
