import {CategoryInterface} from '../../../interfaces/category.interface';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {ChangeCategoryActiveCategory, CreateCategory, EditCategory, LoadCategories} from './category.actions';
import {CategoryService} from '../../../shared/services/category/category.service';
import {of, tap} from 'rxjs';
import {catchError} from 'rxjs/operators';

export interface CategoryStateModel {
  categories: CategoryInterface[];
  loading: boolean;
  error: Error | null;
}

export function getDefaultState() {
  return {
    categories: [],
    loading: false,
    error: null
  };
}


@State<CategoryStateModel>({
  name: 'categoryState',
  defaults: getDefaultState()
})
@Injectable()
export class CategoryState {
  constructor(private categoryService: CategoryService) {
  }

  @Selector()
  static getAllCategories(state: CategoryStateModel) {
    return state.categories;
  }

  @Action(LoadCategories) getCategories(ctx: StateContext<CategoryStateModel>) {
    ctx.patchState({ loading: true, error: null });

    return this.categoryService.getCategories().pipe(
      tap((erg) => {
        ctx.patchState({ loading: false, error: null, categories: erg });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false, error: err });
        return of(null);
      })
    );
  }

  @Action(ChangeCategoryActiveCategory) activeStatus(ctx: StateContext<CategoryStateModel>, payload: ChangeCategoryActiveCategory) {
    return this.categoryService.updateCategoryActiveStatus(payload.id, payload.active);
  }

  @Action(CreateCategory) createCategory(ctx: StateContext<CategoryStateModel>, payload: CreateCategory) {
    return this.categoryService.addCategory(payload.body);
  }

  @Action(EditCategory) editCategory(ctx: StateContext<CategoryStateModel>, payload: EditCategory) {
    return this.categoryService.updateCategory(payload.body.id, payload.body);
  }
}
