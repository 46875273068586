export class Login {
  static readonly type = '[Auth] Login';

  constructor(public email: string, public password: string, public withRefreshToken: boolean = false) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';

  constructor() {}
}

export class RefreshToken {
  static readonly type = '[Auth] Refresh Token';

  constructor() {}
}
