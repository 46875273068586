import {StatusInterface} from '../../../interfaces/status.interface';

export class LoadStatus {
  static readonly type = '[Status] load status';

  constructor() {}
}

export class LoadOkStatus {
  static readonly type = '[Status] Load ok status';

  constructor() {}
}

export class LoadDefaultStatus {
  static readonly type = '[Status] Load default status';

  constructor() {}
}

export class UpdateNoticeStatus {
  static readonly type = '[Status] Update notice status';
  constructor(public noticeId: number, public statusId: number) {
  }
}

export class ChangeStatusActiveStatus {
  static readonly type = '[Status] Change status active status';
  constructor(public id: number, public active: boolean) {
  }
}

export class CreateStatus {
  static readonly type = '[Status] Create status';

  constructor(public body: StatusInterface) {}
}

export class EditStatus {
  static readonly type = '[Status] Edit status';

  constructor(public body: StatusInterface) {}
}
