<div class="item-select" [ngClass]="fullWidth ? 'item-select__full-width' : ''">
  <select [(ngModel)]="selectedId" *ngIf="items$ | async as items" (change)="changeItem()" class="custom-select" [ngClass]="fullWidth ? '' : 'itemSelect'">
    <ng-container *ngFor="let item of items">
      <ng-container *ngIf="isCurrentlySelected(item.id); else normal">
        <option [value]="item.id" selected hidden disabled>{{ item.name }}</option>
      </ng-container>
      <ng-template #normal>
        <option [value]="item.id">
          {{ item.name }}
        </option>
      </ng-template>
    </ng-container>
  </select>
  <div *ngIf="enableClearSelection" class="item-select__clear-selection">
    <fa-icon [icon]="faCross" (click)="clearSelection()" class="ml-2 clear-icon"></fa-icon>
  </div>
</div>
