import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent {
  @Input() on: boolean;
  @Input() offLabel: string;
  @Input() onLabel: string;
  @Output() toggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  onClick(): void {
    this.on = !this.on;
    this.toggled.emit(this.on);
  }

}
