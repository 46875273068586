import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {NoticeInterface} from '../../../interfaces/notice.interface';
import {LoadNotices} from './notice.actions';
import {NoticeService} from '../../../shared/services/notice/notice.service';
import {of, tap, map, catchError} from 'rxjs';
import {PaginationModel} from '../../../shared/components/pagination/pagination.model';

export interface NoticeStateModel {
  allNotices: NoticeInterface[];
  detailNotice: NoticeInterface;
  loading: boolean;
  pagination: PaginationModel;
  error: Error | null;
}

export function getDefaultState() {
  return {
    allNotices: [],
    detailNotice: null,
    pagination: null,
    loading: false,
    error: null
  };
}

@State<NoticeStateModel>({
  name: 'noticeState',
  defaults: getDefaultState()
})
@Injectable()
export class NoticeState {
  constructor(private noticeService: NoticeService) {
  }

  @Selector()
  static getAllNotices(state: NoticeStateModel) {
    return state.allNotices;
  }

  @Selector()
  static noticePagination(state: NoticeStateModel) {
    return state.pagination;
  }

  @Selector()
  static getNoticeDetail(state: NoticeStateModel) {
    return state.detailNotice;
  }

  @Action(LoadNotices) loadNotices(ctx: StateContext<NoticeStateModel>, action: LoadNotices) {
    const { limit, page, categoryId, statusId, userId, showArchived, search, startDate, endDate, clerkId, assigned } = action.body;
    ctx.patchState({ loading: true, error: null, detailNotice: null });

    return this.noticeService.getNotices({ limit, page }, categoryId, statusId, userId, showArchived ? 'yes' : 'no', search, startDate, endDate, clerkId, assigned).pipe(
      tap((erg) => {
        const { pagination, result } = erg;
        ctx.patchState({ loading: false, allNotices: result, error: null, pagination: new PaginationModel(
          pagination?.limit,
          pagination?.page,
          pagination?.total
        )});
      }),
      catchError((err) => {
        ctx.patchState({ loading: false, error: err });
        return of(null);
      })
    );
  }
}
