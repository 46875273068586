import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {RangeDateInterface} from '../components/range-datepicker/range-date.interface';

@Injectable({
  providedIn: 'root'
})
export class FormatTimeService {

  constructor() { }

  formatTimestamp(date: string) {
    return moment(date).format('D.M.YYYY') + ', ' + moment(date).format('HH:mm');
  }

  padZero(num: number) {
    return num < 10 ? '0' + num : '' + num;
  }

  formatToISODateTime(date: string) {
    return moment(date, 'YYYY-MM-DD hh:mm').toISOString();
  }

  formatRangeDate(dates: RangeDateInterface) {
    const hasError = dates.hasError
    let startDate = null
    let endDate = null
    // YYYY-MM-DD
    if (dates.startDate) {
      startDate = this.formatSingleDate(dates.startDate)
    }
    if (dates.endDate) {
      endDate = this.formatSingleDate(dates.endDate)
    }
    return { startDate, endDate, hasError }
  }

  formatSingleDate(date: NgbDate) {
    return `${date.year}-${this.padZero(date.month)}-${this.padZero(date.day)}`
  }
}
