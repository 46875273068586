import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StatusInterface, UpdateStatusInterface } from 'src/app/interfaces/status.interface';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import {StatusHistoryInterface, StatusHistoryResponse} from '../../components/status-history/status-history.interface';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(private http: HttpClient) { }

  getStatus(): Observable<StatusInterface[]> {
    return this.http.get<{ result: StatusInterface[] }>(`${environment.baseUri}/status`).pipe(map((erg) => {
      return erg.result;
    }));
  }

  addStatus(body: { name: string, hexCode: string, icon: string, active: boolean }): Observable<StatusInterface> {
    return this.http.post<StatusInterface>(`${environment.baseUri}/status`, body);
  }

  updateStatusEntity(id: number, body: UpdateStatusInterface) {
    return this.http.put<StatusInterface>(`${environment.baseUri}/status/${id}`, body);
  }

  updateNoticeStatus(id: number, body: { statusId: number }): Observable<any> {
    return this.http.put(`${environment.baseUri}/notice/${id}/status`, body);
  }

  getStatusAdminTool(): Observable<StatusInterface> {
    // get current common status
    return this.http.get<StatusInterface>(`${environment.baseUri}/setting/status`);
  }

  updateStatusAdminTool(statusId: number): Observable<boolean> {
    return this.http.put<boolean>(`${environment.baseUri}/setting/status/${statusId}`, {});
  }

  updateStatusOkTool(statusId: number): Observable<boolean> {
    return this.http.put<boolean>(`${environment.baseUri}/setting/status_ok/${statusId}`, {});
  }

  getStatusOk(): Observable<StatusInterface> {
    return this.http.get<StatusInterface>(`${environment.baseUri}/setting/status_ok`);
  }

  updateStatusActiveStatus(id: number, isActive: boolean): Observable<StatusInterface> {
    // toggle active / inactive
    const body = {active: !isActive};
    return this.http.put<StatusInterface>(`${environment.baseUri}/status/${id}`, body);
  }

  updateStatus(id: number, body: UpdateStatusInterface) {
    return this.http.put<StatusInterface>(`${environment.baseUri}/status/${id}`, body);
  }

  /*
    getHistory() returns the status history of a given notice
    the return data type is an array of StatusHistoryInterface (all status changes of the given notice)
    the http call returns an object with a result-array (data type StatusHistoryResponse)
    which is then being mapped (it returns only the result-array)
   */
  getHistory(id: number): Observable<StatusHistoryInterface[]> {
    return this.http.get<StatusHistoryResponse>(`${environment.baseUri}/notice/${id}/status/history`).pipe(
      map((r) => r.result)
    );
  }

}
