export class PaginationModel {
  constructor(public limit = 0, public page = 0, public total = 0) {
  }

  get currentPage() {
    return Math.max(this.page - 1, 0);
  }

  get totalPages() {
    return Math.ceil(this.total / this.limit);
  }

  get isNeeded() {
    return this.total > this.limit;
  }
}
