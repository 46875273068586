import {StatusInterface} from '../../../interfaces/status.interface';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {StatusService} from '../../../shared/services/status/status.service';
import {of, tap, throwError} from 'rxjs';
import {
  LoadStatus,
  LoadDefaultStatus,
  LoadOkStatus,
  UpdateNoticeStatus,
  ChangeStatusActiveStatus,
  CreateStatus,
  EditStatus
} from './status.actions';
import {catchError} from 'rxjs/operators';

export interface StatusStateModel {
  status: StatusInterface[];
  okStatus: StatusInterface;
  defaultStatus: StatusInterface;
  loading: boolean;
  error: Error | null;
  updateError: Error | null;
}

export function getDefaultState() {
  return {
    status: [],
    loading: false,
    okStatus: null,
    defaultStatus: null,
    error: null,
    updateError: null
  };
}

@State<StatusStateModel>({
  name: 'statusState',
  defaults: getDefaultState()
})
@Injectable()
export class StatusState {
  constructor(private statusService: StatusService) {
  }

  @Selector()
  static getAllStatus(state: StatusStateModel) {
    return state.status;
  }

  @Selector()
  static getOkStatus(state: StatusStateModel) {
    return state.okStatus;
  }

  @Selector()
  static getError(state: StatusStateModel) {
    return state.error;
  }

  @Selector()
  static getUpdateError(state: StatusStateModel) {
    return state.updateError;
  }

  @Selector()
  static getDefaultStatus(state: StatusStateModel) {
    return state.defaultStatus;
  }

  @Action(LoadStatus) loadStatus(ctx: StateContext<StatusStateModel>) {
    ctx.patchState({ loading: true, error: null });

    return this.statusService.getStatus().pipe(
      tap((erg) => {
        ctx.patchState({ status: erg, loading: false, error: null });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false, error: err });
        return of(null);
      })
    );
  }

  @Action(LoadOkStatus) loadOkStatus(ctx: StateContext<StatusStateModel>) {
    ctx.patchState({ loading: true, error: null });

    return this.statusService.getStatusOk().pipe(
      tap((erg) => {
        ctx.patchState({ okStatus: erg, loading: false, error: null });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false, error: err });
        return of(null);
      })
    );
  }

  @Action(LoadDefaultStatus) loadDefaultStatus(ctx: StateContext<StatusStateModel>) {
    ctx.patchState({ loading: true, error: null });

    return this.statusService.getStatusAdminTool().pipe(
      tap((erg) => {
        ctx.patchState({ defaultStatus: erg, loading: false, error: null });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false, error: err });
        return of(null);
      })
    );
  }

  @Action(UpdateNoticeStatus) updateNoticeStatus(ctx: StateContext<StatusStateModel>, action: UpdateNoticeStatus) {
    ctx.patchState({ loading: true, updateError: null });

    return this.statusService.updateNoticeStatus(action.noticeId, { statusId: action.statusId }).pipe(
      tap(() => ctx.patchState({ loading: false, updateError: null })),
      catchError((err) => {
        ctx.patchState({ loading: false, updateError: err });
        return throwError(err);
      })
    );
  }

  @Action(ChangeStatusActiveStatus) activeStatus(ctx: StateContext<StatusStateModel>, payload: ChangeStatusActiveStatus) {
    return this.statusService.updateStatusActiveStatus(payload.id, payload.active);
  }

  @Action(CreateStatus) createStatus(ctx: StateContext<StatusStateModel>, payload: CreateStatus) {
    return this.statusService.addStatus(payload.body);
  }

  @Action(EditStatus) editStatus(ctx: StateContext<StatusStateModel>, payload: EditStatus) {
    return this.statusService.updateStatus(payload.body.id, payload.body);
  }
}
