<div class="modal-header">
  <h4 class="modal-title" id="modal-title"> {{ title }}</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-content></ng-content>
</div>
<div class="modal-footer">
  <button *ngIf="buttonCancel" type="button" class="btn btn-outline-danger" (click)="modal.dismiss('no')">{{ buttonCancel }}</button>
  <button *ngIf="buttonAccept" type="button" class="btn btn-primary" (click)="clickButtonAccept()" [disabled]="hasError">{{ buttonAccept }}</button>
</div>
