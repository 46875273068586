import { AfterViewInit, Directive, ElementRef, Input, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appImage]'
})
// If image returns error -> set source to fallback image
export class ImageDirective implements AfterViewInit {

    private privSrc: string;

    @Input() set src(value: string) {
      this.privSrc = value;
      if (this.imageRef?.nativeElement) {
        this.setImage(this.privSrc);
      }
    }

    constructor(private imageRef: ElementRef) {
    }

    ngAfterViewInit(): void {
        const img = new Image();
        img.onload = () => {
            this.setImage(this.privSrc);
        };

        img.onerror = () => {
            // Set a placeholder image
            this.setImage('assets/fallback_Bild_.png');
        };

        img.src = this.privSrc;
    }

    private setImage(src: string) {
        this.imageRef.nativeElement.setAttribute('src', src);
    }
}
