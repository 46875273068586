import {Action, Selector, State, StateContext} from '@ngxs/store';
import {AuthService} from '../../../shared/services/auth/auth.service';
import {Login, Logout, RefreshToken} from './login.actions';
import {catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {Injectable} from '@angular/core';

export interface AuthStateModel {
  jwtToken: string;
  refreshToken: string;
  id: number;
  loading: boolean;
  error: Error | null;
}

export function getDefaultState() {
  return {
    jwtToken: null,
    refreshToken: null,
    id: null,
    loading: false,
    error: null
  };
}

export const keysToPersist = [
  'authState.jwtToken',
  'authState.refreshToken',
  'authState.id'
];

@State<AuthStateModel>({
  name: 'authState',
  defaults: getDefaultState()
})
@Injectable()
export class AuthState {
  constructor(private authService: AuthService,
              private toastrService: ToastrService) {
  }

  @Selector()
  static isLoggedIn(state: AuthStateModel) {
    return !!state.jwtToken;
  }

  @Selector()
  static jwtToken(state: AuthStateModel) {
    return state.jwtToken;
  }

  @Selector()
  static id(state: AuthStateModel) {
    return state.id;
  }

  @Selector()
  static isEmployee(state: AuthStateModel): boolean {
    try {
      const parsedToken = JSON.parse(atob((state.jwtToken || '').split('.')[1]));
      // @Todo: change this
      return parsedToken.roleId === 3;
    } catch (err) {
      return null;
    }
  }

  @Selector()
  static isLoading(state: AuthStateModel) {
    return state.loading;
  }

  @Action(Login) login(ctx: StateContext<AuthStateModel>, action: Login) {
    ctx.patchState({ error: null, loading: true });

    return this.authService.login(action).pipe(
      tap((response) => {
        ctx.patchState({
          jwtToken: response.signedJwt,
          refreshToken: response.refreshToken,
          id: response.id,
          error: null,
          loading: false
        });
      }),
      catchError((error) => {
        this.toastrService.error('Login nicht möglich! Bitte Zugangsdaten prüfen!', 'Fehler..');
        ctx.patchState({ error, loading: false });
        return of(null);
      })
    );
  }

  @Action(Logout) logout(context: StateContext<AuthStateModel>) {
    context.setState(getDefaultState());
  }

  @Action(RefreshToken) refreshToken(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({ error: null, loading: true });
    const token = ctx.getState().refreshToken;

    return this.authService.refreshToken(token).pipe(
      tap((response) => {
        ctx.patchState({
          jwtToken: response.signedJwt,
          refreshToken: response.refreshToken,
          error: null,
          loading: false
        });
      }),
      catchError((err) => {
        ctx.patchState({ error: err, loading: false });
        return of(null);
      })
    );
  }
}
