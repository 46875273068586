import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {RangeDateInterface} from './range-date.interface';
import {CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n} from './datepicker-adapter';
import {faCalendarAlt, faTimes} from '@fortawesome/free-solid-svg-icons';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-range-datepicker',
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }
  ]
})
export class RangeDatepickerComponent implements OnInit {

  @Input()
  defaultFromDate: string;
  @Input()
  defaultToDate: string;
  // default: don't use default date
  @Input()
  useDefault: boolean = false
  @Input()
  enableClearSelection: boolean = false
  @Input()
  showLabels:boolean = true

  @Output()
  updateDates: EventEmitter<RangeDateInterface> = new EventEmitter<RangeDateInterface>();

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  errorFromDateFormatMsg: string;
  errorToDateFormatMsg: string;
  errorDateRangeMsg: string;

  faCalendar = faCalendarAlt;
  faCross = faTimes;

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, private translateService: TranslateService,
              private dateAdapter: NgbDateAdapter<string>) {
  }

  ngOnInit(): void {
    // should the default dates be used (either default input dates from parent or the defined ones here)
    if (this.useDefault) {
      // default of start and end date
      if (this.defaultFromDate) {
        const data = this.dateAdapter.fromModel(this.defaultFromDate);
        this.fromDate = new NgbDate(data.year, data.month, data.day);
      } else {
        this.fromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 7);
      }
      if (this.defaultToDate) {
        const data = this.dateAdapter.fromModel(this.defaultToDate);
        this.toDate = new NgbDate(data.year, data.month, data.day);
      } else {
        this.toDate = this.calendar.getToday();
      }
    }
    this.emitDates();
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.emitDates();
  }

  emitDates() {
    // are there any errors?
    const hasError = !!(this.errorFromDateFormatMsg || this.errorToDateFormatMsg || this.errorDateRangeMsg);
    this.updateDates.emit({ startDate: this.fromDate, endDate: this.toDate, hasError });
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string, isFromDate): NgbDate | null {
    const parsed = this.formatter.parse(input);
    if (isFromDate) {
        // if input is empty --> no error
        if (!(input === '')) {
          if (!this.calendar.isValid(NgbDate.from(parsed))) {
            // error message according to date, input validation in html
            this.errorFromDateFormatMsg = this.translateService.instant('rangeDatePicker.errStartDate');
          } else {
            this.errorFromDateFormatMsg = null;
          }
        } else {
          this.errorFromDateFormatMsg = null;
        }
      } else {
        if (!(input === '')) {
          if (!this.calendar.isValid(NgbDate.from(parsed))) {
            this.errorToDateFormatMsg = this.translateService.instant('rangeDatePicker.errEndDate');
          } else {
            this.errorToDateFormatMsg = null;
          }
        } else {
          this.errorToDateFormatMsg = null;
        }
      }
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  isDateRangeValid() {
    if (this.fromDate && this.toDate) {
      // error if from date is bigger than to date
      if (this.fromDate.after(this.toDate)) {
        this.errorDateRangeMsg = this.translateService.instant('rangeDatePicker.errEndDateBefore');
      } else {
        this.errorDateRangeMsg = null;
      }
    }
  }

  clearDates() {
    this.fromDate = null;
    this.toDate = null;
    this.emitDates()
  }

}
