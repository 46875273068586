import {CategoryInterface} from '../../../interfaces/category.interface';

export class LoadCategories {
  static readonly type = '[Category] load categories';

  constructor() {}
}

export class ChangeCategoryActiveCategory {
  static readonly type = '[Category] Change category active status';
  constructor(public id: number, public active: boolean) {
  }
}

export class CreateCategory {
  static readonly type = '[Category] Create category';

  constructor(public body: CategoryInterface) {}
}

export class EditCategory {
  static readonly type = '[Category] Edit category';

  constructor(public body: CategoryInterface) {}
}
