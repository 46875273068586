<div class="clerk-dropdown">
  <ng-select
    [items]="(clerks$ | async)"
    bindLabel="name"
    bindValue="id"
    [ngModel]="selectedClerks"
    [multiple]="true"
    [compareWith]="compareWith"
    (change)="clerksSelected($event)">
  </ng-select>
</div>
