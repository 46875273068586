<div *ngIf="isNeeded">
  <nav>
    <ul class="pagination">
      <li class="pagination__item" [class.pagination__item--disabled]="isFirst">
        <a class="pagination__item__link" (click)="goTo(0, $event)">
          <fa-icon [icon]="angleDoubleLeft"></fa-icon>
        </a>
      </li>
      <li class="pagination__item" [class.pagination__item--disabled]="!hasPrev">
        <a class="pagination__item__link" (click)="goTo(current - 1, $event)">
          <fa-icon [icon]="angleLeft"></fa-icon>
        </a>
      </li>
      <li class="pagination__item" *ngFor="let page of pages" [class.pagination__item--active]="current === page">
        <a class="pagination__item__link" (click)="goTo(page, $event)">{{ page + 1 }}</a>
      </li>
      <li class="pagination__item" [class.pagination__item--disabled]="!hasNext">
        <a class="pagination__item__link" (click)="goTo(current + 1, $event)">
          <fa-icon [icon]="angleRight"></fa-icon>
        </a>
      </li>
      <li class="pagination__item" [class.pagination__item--disabled]="isLast">
        <a class="pagination__item__link" (click)="goTo(total - 1, $event)">
          <fa-icon [icon]="angleDoubleRight"></fa-icon>
        </a>
      </li>
    </ul>
  </nav>

  <div class="text-center subtitle">
    {{ 'pagination.label' | translate: { current: current + 1, of: total } }}
  </div>
</div>
