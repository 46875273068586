import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NavbarComponent} from './navbar.component';
import {TranslateModule} from '@ngx-translate/core';
import {AuthState} from '../login/state/login.state';
import {NgxsModule} from '@ngxs/store';
import {NgbCollapseModule} from '@ng-bootstrap/ng-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [NavbarComponent],
  imports: [
    RouterModule,
    TranslateModule.forChild(),
    CommonModule,
    NgbCollapseModule,
    FontAwesomeModule
  ],
  exports: [NavbarComponent]
})
export class NavbarModule { }
