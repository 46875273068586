import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AnimationOptions} from 'ngx-lottie';

@Component({
  selector: 'app-lottie-alert',
  templateUrl: './lottie-alert.component.html',
  styleUrls: ['./lottie-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LottieAlertComponent implements OnInit {
  @Input() error: Error;

  isOpen = false;

  lottieOptions: AnimationOptions = {
    path: '/assets/lottie/error.json'
  };

  onClick() {
    this.isOpen = !this.isOpen;
    this.changeDetector.detectChanges();
  }

  constructor(public changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

}
