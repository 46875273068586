<nav class="navbar navbar-expand-xl mb-3" *ngIf="{ loggedIn: loggedIn | async, isEmployee: isEmployee$ | async } as context">
  <a class="navbar-brand" [routerLink]="'.'">{{ 'navbar.appName' | translate }}</a>

  <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
    &#9776;
  </button>

  <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
    <ul class="navbar-nav">
      <li class="nav-item d-flex align-items-center" *ngIf="!context.loggedIn && !context.isEmployee">
        <a class="nav-link" routerLink="/login" (click)="isMenuCollapsed = true"><fa-icon [icon]="personOutline" class="icon"></fa-icon>
          {{ 'navbar.login' | translate }}</a>
      </li>
      <li class="nav-item d-flex align-items-center" *ngIf="context.loggedIn && !context.isEmployee">
        <a class="nav-link text-center" routerLink="." (click)="isMenuCollapsed = true"><fa-icon [icon]="faList" class="icon"></fa-icon>{{ 'navbar.noticeOverview' | translate }}</a>
      </li>
      <li class="nav-item d-flex align-items-center" *ngIf="context.loggedIn && !context.isEmployee">
        <a class="nav-link text-center" routerLink="/map" (click)="isMenuCollapsed = true"><fa-icon [icon]="faMapMarkedAlt" class="icon"></fa-icon>
          {{ 'navbar.map' | translate }}</a>
      </li>
      <li class="nav-item d-flex align-items-center" *ngIf="context.loggedIn && !context.isEmployee">
        <a class="nav-link text-center" routerLink="/article" (click)="isMenuCollapsed = true"><fa-icon [icon]="faNewspaper" class="icon"></fa-icon>
          {{ 'navbar.articles' | translate }}</a>
      </li>
      <li class="nav-item d-flex align-items-center" *ngIf="context.loggedIn && !context.isEmployee">
        <a class="nav-link text-center" routerLink="/statistics" (click)="isMenuCollapsed = true"><fa-icon [icon]="faChart" class="icon"></fa-icon>
          {{ 'navbar.reporting' | translate }}</a>
      </li>
      <li class="nav-item d-flex align-items-center" *ngIf="context.loggedIn && !context.isEmployee">
        <a class="nav-link text-center" routerLink="/users" (click)="isMenuCollapsed = true"><fa-icon [icon]="faUsers" class="icon"></fa-icon>
          {{ 'navbar.user' | translate }}</a>
      </li>
      <li class="nav-item d-flex align-items-center" *ngIf="context.loggedIn && !context.isEmployee">
        <a class="nav-link text-center" routerLink="/settings" (click)="isMenuCollapsed = true"><fa-icon [icon]="faCog" class="icon"></fa-icon>
          {{ 'navbar.settings' | translate }}</a>
      </li>
    </ul>
  </div>
  <button class="btn extra-element" *ngIf="context.loggedIn" routerLink="/add-notice">
    <fa-icon [icon]="faAdd" class="icon"></fa-icon>
    {{ 'navbar.newNotice' | translate }}
  </button>
  <button class="btn extra-element" *ngIf="context.loggedIn  && !context.isEmployee" (click)="openCSVModal()">
    <fa-icon [icon]="faExport" class="icon"></fa-icon>
    {{ 'navbar.csv' | translate }}
  </button>
  <button class="btn btn-logout mx-1" *ngIf="context.loggedIn" (click)="logout()">{{ 'navbar.logout' | translate }}</button>
</nav>

<!-- Loading spinner
          -> works in the entire app because the navbar is displayed in every page, isLoading is a BehaviourSubject (async)-->
<div *ngIf="loaderService.isLoading | async" class="d-flex justify-content-center spinner-custom-background">
  <div class="spinner-border admin-green spinner-custom" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
