import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {PaginationModel} from './pagination.model';
import {faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  angleLeft = faAngleLeft;
  angleDoubleLeft = faAngleDoubleLeft;
  angleRight = faAngleRight;
  angleDoubleRight = faAngleDoubleRight;

  @Output() pageChanged = new EventEmitter<number>();

  @Input() current = 0;
  @Input() total = 0;
  @Input() maxVisible = 7;
  @Input() set pagination(pagination: PaginationModel) {
    if (pagination) {
      this.current = pagination.currentPage;
      this.total = pagination.totalPages;
    } else {
      this.current = 0;
      this.total = 0;
    }
  }

  constructor() {}

  get hasPrev(): boolean {
    return this.current > 0 && this.total > 0;
  }

  get hasNext(): boolean {
    return this.current < this.total - 1 && this.total > 0;
  }

  get isLast(): boolean {
    return this.current >= this.total - 1;
  }

  get isFirst(): boolean {
    return this.current <= 0;
  }

  get pages() {
    const index = (this.maxVisible - 1) / 2;
    const first =
      this.current < index
        ? 0
        : this.current < this.total - index
          ? this.current - index
          : this.total - this.maxVisible;
    const last = this.current > this.total - index ? this.total : first + this.maxVisible;
    return Array.from(Array(this.total).keys()).slice(first, last);
  }

  get isNeeded() {
    return this.total > 1;
  }

  goTo(page: number, event: Event) {
    event.preventDefault();
    this.pageChanged.emit(page + 1);
  }

  ngOnInit() {}
}
