
import {Injectable, NgZone} from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from '../services/auth/auth.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {catchError, filter, finalize, switchMap, take, tap} from 'rxjs/operators';
import { Router } from '@angular/router';
import {Store} from '@ngxs/store';
import {AuthState} from '../../modules/login/state/login.state';
import {Logout, RefreshToken} from '../../modules/login/state/login.actions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LottieAlertComponent} from '../components/lottie-alert/lottie-alert.component';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    // Refresh Token Subject tracks the current token, or is null if no token is currently
    // available (e.g. refresh pending).
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );
    constructor(public auth: AuthService,
                public modal: NgbModal,
                private ngZone: NgZone,
                private store: Store,
                private router: Router) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(this.addAuthenticationToken(request)).pipe(
            catchError((err) => {
                if (err.status >= 500 || err.status === 0) {
                  if (!this.modal.hasOpenModals()) {
                    const modal = this.modal.open(LottieAlertComponent, { size: 'lg' });
                    modal.componentInstance.error = err;
                    return throwError(err);
                  }
                }
                // Log user out if refresh-call failed
                if (request.url.includes('refresh')) {
                  this.store.dispatch(new Logout());
                  this.ngZone.run((() => {
                    this.router.navigate(['/login']);
                  }));
                  return throwError(err);
                }
                // ignore if user login
                if (request.url.includes('login')) {
                  return throwError(err);
                }
                if (err.status !== 401) {
                  return throwError(err);
                }
                if (this.refreshTokenInProgress) {
                    return this.refreshTokenSubject.pipe(
                        filter(result => result !== null),
                        take(1),
                        switchMap(() => next.handle(this.addAuthenticationToken(request)))
                    );
                } else {
                    this.refreshTokenInProgress = true;
                    this.refreshTokenSubject.next(null);
                    return this.store.dispatch(new RefreshToken()).pipe(
                      tap((erg) => {
                        this.refreshTokenInProgress = false;
                        if (!erg) {
                          this.store.dispatch(new Logout());
                          return throwError(err);
                        }
                        this.refreshTokenSubject.next(true);
                      }),
                      switchMap(() => next.handle(this.addAuthenticationToken(request)))
                    );
                }
            })
        );
    }

    addAuthenticationToken(request: HttpRequest<any>) {
        // Get access token from Local Storage
      const signedJwt = this.store.selectSnapshot(AuthState.jwtToken);

        // If access token is null this means that user is not logged in
        // And we return the original request
      if (!signedJwt) {
            return request.clone({
              setParams: {
                municipalityId: '1'
              }
            });
        }

        // We clone the request, because the original request is immutable
      return request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + signedJwt
            },
            setParams: {
              municipalityId: '1'
            }
        });
    }
}
