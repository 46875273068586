import {ClerkInterface, UserInterface} from '../../../interfaces/user.interface';
import {UserService} from '../../../shared/services/user/user.service';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {ChangeBanStatusUser, ChangeRole, LoadClerks, LoadUsers} from './user.actions';
import {Injectable} from '@angular/core';
import {ArticleStateModel} from '../../article/state/article.state';
import {of, tap} from 'rxjs';
import {PaginationModel} from '../../../shared/components/pagination/pagination.model';
import {catchError} from 'rxjs/operators';

export interface UserStateModel {
  allUsers: UserInterface[];
  clerks: ClerkInterface[];
  pagination: PaginationModel;
  loading: boolean;
  error: Error | null;
}

export function getDefaultState() {
  return {
    allUsers: [],
    clerks: [],
    pagination: null,
    loading: false,
    error: null
  };
}

@State<UserStateModel>({
  name: 'userState',
  defaults: getDefaultState()
})
@Injectable()
export class UserState {
  constructor(private userService: UserService) {
  }

  @Selector()
  static pagination(state: UserStateModel) {
    return state.pagination;
  }

  @Selector()
  static allUsers(state: UserStateModel) {
    return state.allUsers;
  }

  @Selector()
  static clerks(state: UserStateModel) {
    return state.clerks;
  }

  @Action(ChangeBanStatusUser) changeBanStatus(ctx: StateContext<UserStateModel>, payload: ChangeBanStatusUser) {
    return this.userService.changeUserIsBanned(payload.id, payload.isBanned);
  }

  @Action(ChangeRole) changeIsAdminStatus(ctx: StateContext<UserStateModel>, payload: ChangeRole) {
    return this.userService.changeRoleId(payload.id, payload.roleIds);
  }

  @Action(LoadUsers) loadUsers(ctx: StateContext<UserStateModel>, payload: LoadUsers) {
    ctx.patchState({ error: null, loading: true });
    const { body: { limit, page, search } } = payload;

    return this.userService.getUsers({ limit, page }, search).pipe(
      tap((response) => {
        const { pagination, result } = response;
        ctx.patchState({
          allUsers: result,
          loading: false,
          error: null,
          pagination: new PaginationModel(
            pagination.limit,
            pagination.page,
            pagination.total
        )});
      }),
      catchError((err) => {
        ctx.patchState({ loading: false, error: err });
        return of(null);
      })
    );
  }

  @Action(LoadClerks) loadClerks(ctx: StateContext<UserStateModel>, payload: LoadClerks) {
    ctx.patchState({ error: null, loading: true });
    const { body: { limit, page, search } } = payload;

    return this.userService.getClerks({ limit, page }, search).pipe(
      tap((response) => {
        const { pagination, result } = response;
        ctx.patchState({
          clerks: result.map((clerk) => {
            return {
              id: clerk.id,
              name: `${clerk.firstName} ${clerk.lastName}`
            };
          }),
          loading: false,
          error: null,
          pagination: new PaginationModel(
            pagination.limit,
            pagination.page,
            pagination.total
          )});
      }),
      catchError((err) => {
        ctx.patchState({ loading: false, error: err });
        return of(null);
      })
    );
  }


}
