import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import {ToastrService} from 'ngx-toastr';
import {Store} from '@ngxs/store';
import {AuthState} from '../../modules/login/state/login.state';
import {Logout} from '../../modules/login/state/login.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public store: Store, public router: Router, private toastrService: ToastrService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const canActivate = this.store.selectSnapshot(AuthState.isLoggedIn);
    const jwtToken = this.store.selectSnapshot(AuthState.jwtToken);
    if (!canActivate) {
      this.store.dispatch(new Logout());
      return false;
    }
    try {
      if (this.store.selectSnapshot(AuthState.isEmployee)) {
        this.router.navigateByUrl('add-notice');
        return false;
      }
      // @Todo: this needs to be changed in the future to check per municipality (or endpoint) but for now it'll do
      if (!(JSON.parse(atob(jwtToken.split('.')[1]))).isAdmin) {
        this.store.dispatch(new Logout());
        this.toastrService.error('Sie haben keine Berechtigung um Inhalte anzuzuzeigen!', 'Berechtigungsfehler...');
        return false;
      }
    } catch (err) {
      this.store.dispatch(new Logout());
      this.toastrService.error('Sie haben keine Berechtigung um Inhalte anzuzuzeigen!', 'Berechtigungsfehler...');
      return false;
    }
    return canActivate;
  }

}
