import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input()
  title: string;
  @Input()
  buttonAccept: string;
  @Input()
  buttonCancel: string;
  @Input()
  hasError: boolean;
  @Output()
  buttonAcceptClicked: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void { }

  clickButtonAccept() {
    this.buttonAcceptClicked.emit(true);
    this.modal.close(true);
  }

}
