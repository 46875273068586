import { Injectable, OnDestroy } from '@angular/core';
import {AdminData, NoticeInterface} from '../../../interfaces/notice.interface';
import { Observable, of, Subject } from 'rxjs';
import * as moment from 'moment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {PaginationInterface} from '../../../util/pagination.interface';
import {ResultPagination} from '../../../util';
import { LatLng } from 'leaflet';
import {UserInterface} from '../../../interfaces/user.interface';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NoticeService implements OnDestroy {
  private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  getNotices(pagination: PaginationInterface, categoryId?: number, statusId?: number,
             userId?: number, showArchived?: string, search?: string, startDate?: string, endDate?: string, clerkId?: number, assigned?: string): Observable<ResultPagination<NoticeInterface>> {
    let httpHeaders = new HttpParams().set('limit', '' + pagination.limit).set('page', '' + pagination.page);
    if (statusId) {
      httpHeaders = httpHeaders.set('statusId', '' + statusId);
    }
    if (assigned) {
      httpHeaders = httpHeaders.set('assigned', '' + assigned);
    }
    if (categoryId) {
      httpHeaders = httpHeaders.set('categoryId', '' + categoryId);
    }
    if (userId) {
      httpHeaders = httpHeaders.set('userId', '' + userId);
    }
    if (showArchived !== undefined) {
      httpHeaders = httpHeaders.set('archived', '' + showArchived);
    }
    if (search) {
      httpHeaders = httpHeaders.set('search', search);
    }
    if (startDate) {
      httpHeaders = httpHeaders.set('startDate', startDate);
    }
    if (endDate) {
      httpHeaders = httpHeaders.set('endDate', endDate);
    }
    if (clerkId) {
      httpHeaders = httpHeaders.set('clerkId', clerkId);
    }
    return this.http.get<ResultPagination<NoticeInterface>>(`${environment.baseUri}/notice/admin/nearby`, { params: httpHeaders });
  }

  getUserNotices(pagination: PaginationInterface, userId: number): Observable<ResultPagination<NoticeInterface>> {
    const httpHeaders = new HttpParams().set('limit', '' + pagination.limit).set('page', '' + pagination.page);
    return this.http.get<ResultPagination<NoticeInterface>>(`${environment.baseUri}/notice/admin/nearby`, { params: httpHeaders });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.componentDestroyed$.unsubscribe();
  }

  getNotice(id: number): Observable<NoticeInterface> {
    return this.http.get<NoticeInterface>(`${environment.baseUri}/notice/${id}`).pipe(
      map((notice) => {
        const { clerks, ...rest } = notice;
        const mappedClerks = clerks.map((clerk) => ({...clerk, name: `${clerk.firstName} ${clerk.lastName}` }));
        return { ...rest, clerks: mappedClerks };
      })
    );
  }

  createNotice(body: { categoryId: number, description: string, additionalFieldValue?: string, latitude: number, longitude: number, base64Image?: string, imageType?: string
    houseNumber: string, postCode: string, street: string, city: string
  }): Observable<any> {
    return this.http.post(`${environment.baseUri}/notice`, body);
  }

  updateNotice(id, body: Partial<{ information: string,
    categoryId: number, description: string, additionalFieldValue?: string, street: string, houseNumber: string,
    postCode: string, city: string, lat: string, lng: string }>): Observable<any> {
    return this.http.put(`${environment.baseUri}/notice/${id}`, body);
  }

  updateClerks(id, body: { clerkIds: number[] }): Observable<any> {
    console.log(body.clerkIds)
    return this.http.put(`${environment.baseUri}/notice/${id}/clerks`, body);
  }

  updateLocation(id, body: LatLng): Observable<any> {
    const { lat, lng } = body;
    return this.http.put(`${environment.baseUri}/notice/${id}`, { lat: String(lat), lng: String(lng) });
  }

  updateAdminData(id, body: AdminData): Observable<any> {
    // startDate, endDate, internalNotice as adminData
    return this.http.put (`${environment.baseUri}/notice/${id}`, body);
  }

  async changeAppImage(image: File): Promise<any> {
    let base64String: string | ArrayBuffer = '';
    const reader = new FileReader();
    reader.readAsDataURL(image);
    await new Promise((resolve) => {
      reader.onload = () => {
        base64String = reader.result;
        resolve('yes');
      };
    });
    const body = {
      base64String: base64String.substr(base64String.indexOf(',') + 1)
    };
    return this.http.put(`${environment.baseUri}/setting/image`, body).toPromise();
  }

  getAppImage(): Observable<any> {
    return this.http.get(`${environment.baseUri}/setting/image`);
  }

  getGraph(): Observable<any> {
    return this.http.get(`${environment.baseUri}/notice/graph`);
  }

  getCSV(startDate?: string, endDate?: string, categoryId?: number, statusId?: number, archived?: boolean, clerks?: string): Observable<any> {
    let httpHeaders = new HttpParams();
    if (startDate) {
      httpHeaders = httpHeaders.set('startDate', startDate);
    }
    if (endDate) {
      httpHeaders = httpHeaders.set('endDate', endDate);
    }
    if (categoryId) {
      httpHeaders = httpHeaders.set('categoryId', '' + categoryId);
    }
    if (statusId) {
      httpHeaders = httpHeaders.set('statusId', '' + statusId);
    }
    if (archived) {
      httpHeaders = httpHeaders.set('isArchived', 'yes');
    }
    if (clerks) {
      httpHeaders = httpHeaders.set('clerks', clerks);
    }
    return this.http.get(`${environment.baseUri}/notice/csv`, { params: httpHeaders, responseType: 'blob' });
  }

  archiveNotice(id: number, archived: boolean) {
    return this.http.put(`${environment.baseUri}/notice/${id}`, { archived });
  }

/*
  getNoticesTest(): Observable<NoticeInterface[]> {
    const notices: NoticeInterface[] =
      [
        {
          id: 1,
          description: 'Das ist die Beschreibung. Die Beschreibung muss eine bestimmte Länge haben,
          damit man sehen kann, wie sie sich auf der Seite verhält.',
          timestamp: '2020-02-29T14:49:16.057Z',
          category: {
            id: 2,
            hexCode: '#2a5898',
            icon: 'grid',
            name: 'Kanalgebrechen',
            active: true
          },
          street: 'Waldweg',
          houseNumber: '20',
          postCode: '2020',
          city: 'Hollabrunn',
          imageUri: 'OzLuAYYBCkxAG7Fhz1rR_Screenshot_20200211-075533_Gallery.jpg',
          latitude: 48.5656,
          longitude: 16.1,
          status: {
            id: 1,
            name: 'noch nicht bearbeitet',
            hexCode: '#e60000',
            icon: 'paper-plane',
            active: true
          },
          user: {
            id: 20,
            firstName: 'Markus',
            lastName: 'Mustermann',
            email: 'markus.mustermann@gmx.at',
            isBanned: false
          }
        },
        {
          id: 2,
          description: 'Das ist die Beschreibung von Meldung #2.',
          timestamp: '2020-05-03T14:49:16.057Z',
          category: {
            id: 2,
            hexCode: '#2a5898',
            icon: 'grid',
            name: 'Kanalgebrechen',
            active: true
          },
          street: 'Waldweg',
          houseNumber: '20',
          postCode: '2020',
          city: 'Hollabrunn',
          imageUri: 'OzLuAYYBCkxAG7Fhz1rR_Screenshot_20200211-075533_Gallery.jpg',
          latitude: 48.5698,
          longitude: 16.07,
          status: {
            id: 1,
            name: 'noch nicht bearbeitet',
            hexCode: '#e60000',
            icon: 'paper-plane',
            active: true
          },
          user: {
            id: 20,
            firstName: 'Max',
            lastName: 'Mustermann',
            email: 'max.mustermann@gmx.at',
            isBanned: false
          }
        }
      ];
    return of(notices) as Observable<NoticeInterface[]>;
  }
 */

}
