<app-modal
  title="{{ 'excelExportModal.title' | translate }}"
  buttonAccept="{{ 'excelExportModal.exportButton' | translate }}"
  (buttonAcceptClicked)="exportCSV()" [hasError]="hasError">

  <div class="row">
    <div class="col">
      <div class="input-group justify-content-center">
        <app-range-datepicker (updateDates)="handleUpdateDates($event)" [enableClearSelection]="true"></app-range-datepicker>
      </div>
    </div>
  </div>
  <hr />
  <div class="row mt-1">
    <div class="col column-date">
      <label for="status-select" class="input-label">{{ 'excelExportModal.status' | translate }}</label>
      <app-item-select id="status-select" [items$]="status$" [selectedId]="currentStatus?.id" (itemChanged)="handleChangeStatus($event)" [fullWidth]="true"></app-item-select>
    </div>
    <div class="col column-date">
      <label for="category-select" class="input-label">{{ 'excelExportModal.category' | translate }}</label>
      <app-item-select id="category-select" [items$]="categories$" [selectedId]="currentCategory?.id" (itemChanged)="handleChangeCategory($event)" [fullWidth]="true"></app-item-select>
    </div>
  </div>
  <hr />
  <div class="row mt-1">
    <div class="col">
      <label class="input-label">{{ 'excelExportModal.clerk' | translate }}</label>
      <app-clerk-dropdown (selected)="handleChangeClerks($event)" [selectedClerks]="null"></app-clerk-dropdown>
    </div>
    <div class="col form-check column-date mx-3 mt-4">
      <input type="checkbox" class="form-check-input" [(ngModel)]="archived">
      <label class="form-check-label">{{ 'excelExportModal.onlyArchivedNotices' | translate }}</label>
    </div>
  </div>
</app-modal>
