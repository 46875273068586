import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {ItemInputInterface, ItemSelectPayload} from './item-select.interface';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-item-select',
  templateUrl: './item-select.component.html',
  styleUrls: ['./item-select.component.scss']
})
export class ItemSelectComponent implements OnInit {
  @Input()
  items$: Observable<ItemInputInterface[]>;
  @Input()
  selectedId: number;
  // fullWidth defines whether the select should be full width or not (see notice-list for example)
  // default is false since most components should not be full width
  @Input()
  fullWidth = false;
  @Input()
  enableClearSelection = false;
  @Output()
  itemChanged: EventEmitter<ItemSelectPayload> = new EventEmitter<ItemSelectPayload>();

  faCross = faTimes;

  constructor() { }

  ngOnInit(): void {
  }

  isCurrentlySelected(id: number) {
    return id === this.selectedId;
  }

  changeItem(): void {
    const val = { id: Number(this.selectedId) };
    this.itemChanged.emit(val);
  }

  clearSelection() {
    if (this.enableClearSelection) {
      // reset selectedId
      this.selectedId = null;
      // change is currently selected
      this.isCurrentlySelected(-1);
      // emit event --> current selection should be null (to update data)
      this.itemChanged.emit(null);
    }
  }

}
