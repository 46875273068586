import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import locale from '@angular/common/locales/de-AT';
import localExtraAt from '@angular/common/locales/extra/de-AT';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RefreshTokenInterceptor} from './shared/interceptors/auth.interceptor.service';
import {UniversalModule} from './shared/universal.module';
import { CsvModalComponent } from './shared/components/csv-modal/csv-modal.component';
import {LoaderInterceptor} from './shared/services/loader/loader-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import {TranslateModule} from '@ngx-translate/core';
import {NgxsModule} from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import {AuthState, keysToPersist as authKeysToPersist} from './modules/login/state/login.state';
import {environment} from '../environments/environment';
import {NavbarModule} from './modules/navbar/navbar.module';
import {StatusState} from './modules/status/state/status.state';
import {CategoryState} from './modules/categories/state/category.state';
import {NoticeState} from './modules/notice-list/state/notice.state';
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';
import {NgSelectModule} from '@ng-select/ng-select';
import {UserState} from './modules/users/state/user.state';

export function playerFactory() {
  return player;
}

registerLocaleData(locale, 'de-AT', localExtraAt);

// define more than one http interceptor (token refresh and loader/loading)
const interceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true},
  { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true}
];

@NgModule({
    declarations: [
        AppComponent,
        CsvModalComponent
    ],
    imports: [
        NgbModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        FontAwesomeModule,
        FormsModule,
        UniversalModule,
        AngularFileUploaderModule,
        BrowserAnimationsModule,
        LottieModule.forRoot({ player: playerFactory }),
        NgxsModule.forRoot([StatusState, AuthState, CategoryState, NoticeState, UserState], { developmentMode: false }),
        NgxsStoragePluginModule.forRoot({
            key: [
                ...authKeysToPersist
            ]
        }),
        NgxsLoggerPluginModule.forRoot({ disabled: true }),
        TranslateModule.forRoot({ defaultLanguage: 'de' }),
        ToastrModule.forRoot({
            timeOut: 4000,
            closeButton: true,
            preventDuplicates: true,
            countDuplicates: true,
            progressBar: true,
            positionClass: 'toast-bottom-right'
        }),
        NavbarModule,
        NgSelectModule
    ],
    providers: [interceptorProviders],
    bootstrap: [AppComponent]
})
export class AppModule { }
