import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';


const routes: Routes = [
  { path: 'notice',
    loadChildren: () => import('./modules/notice-page/notice-page.module').then(m => m.NoticePageModule),
    canActivate: [AuthGuard]
  },
  { path: 'map',
    loadChildren: () => import('./modules/map-page/map-page.module').then(m => m.MapPageModule),
    canActivate: [AuthGuard]
  },
  { path: 'statistics',
    loadChildren: () => import('./modules/statistics/statistics.module').then(m => m.StatisticsModule),
    canActivate: [AuthGuard]
  },
  { path: 'settings',
    loadChildren: () => import('./modules/settings-page/settings-page.module').then(m => m.SettingsPageModule),
    canActivate: [AuthGuard]
  },
  { path: 'notice/:id',
    loadChildren: () => import('./modules/notice-detail-page/notice-detail-page.module')
      .then(m => m.NoticeDetailPageModule),
    canActivate: [AuthGuard]
  },
  { path: 'users',
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard] },
  { path: 'categories',
    loadChildren: () => import('./modules/categories/categories.module').then(m => m.CategoriesModule),
    canActivate: [AuthGuard]
  },
  { path: 'status',
    loadChildren: () => import('./modules/status/status.module').then(m => m.StatusModule),
    canActivate: [AuthGuard]
  },
  { path: 'user/:id',
    loadChildren: () => import('./modules/user-detail-page/user-detail-page.module').then(m => m.UserDetailPageModule),
    canActivate: [AuthGuard]
  },
  { path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  { path: 'add-notice',
    loadChildren: () => import('./modules/add-notice/add-notice.module').then(m => m.AddNoticeModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'article',
    loadChildren: () => import('./modules/article/article.module').then((m) => m.ArticleModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'notice',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
