export const translations = {
  navbar: {
    appName: 'Melde-App',
    login: 'Login',
    noticeOverview: 'Meldungsliste',
    map: 'Karte',
    articles: 'Nachrichten',
    reporting: 'Auswertungen',
    settings: 'Einstellungen',
    other: 'Sonstiges',
    newNotice: 'Neue Meldung',
    csv: 'Excel Export',
    logout: 'Abmelden',
    user: 'Benutzer'
  },
  notice: {
    filterCategory: 'nach Kategorien filtern',
    filterStatus: 'nach Status filtern',
    filterClerk: 'nach Sachbearbeiter*in filtern',
    btnResetFilter: 'Filter zurücksetzen'
  },
  noticeDetail: {
  },
  lottieAlert: {
    buttonCaption: 'Zeige Details'
  },
  pagination: {
    label: 'Seite {{ current }} von {{ of }}',
    total: 'Ergebnisse gesamt'
  },
  categoryStatusGeneric: {
    activate: 'Aktiv setzen',
    reset: 'Zurücksetzen',
    colorLabel: 'Klicken, um die Farbe auszuwählen.',
    internalLabel: 'Diese Kategorie ist intern.',
    addObjectButtonLabel: 'Hinzufügen'
  },
  category: {
    tableHeaderName: 'Kategorie',
    tableHeaderEdit: 'Editieren',
    tableHeaderDelete: 'Löschen',
    tableHeaderInternal: 'Interne Kategorie',
    headline: 'Kategorien verwalten',
    alertCaption: 'Kategorie',
    alertCreatedCaption: 'Kategorie wurde erfolgreich erstellt',
    alertUpdatedCaption: 'Kategorie wurde erfolgreich editiert!'
  },
  addEditCategory: {
    labelName: 'Kategorie Name',
    placeholderName: 'z.B. Beleuchtung',
    editButtonCaption: 'Kategorie editieren',
    createButtonCaption: 'Kategorie hinzufügen'
  },
  status: {
    tableHeaderName: 'Status',
    tableHeaderEdit: 'Editieren',
    tableHeaderDelete: 'Löschen',
    headline: 'Status verwalten',
    alertCaption: 'Status',
    alertCreatedCaption: 'Status wurde erfolgreich erstellt',
    alertUpdatedCaption: 'Status wurde erfolgreich editiert!'
  },
  addEditStatus: {
    labelName: 'Status Name',
    placeholderName: 'z.B. offen',
    editButtonCaption: 'Status editieren',
    createButtonCaption: 'Status hinzufügen'
  },
  article : {
    title: 'Nachricht erstellen',
    editTitle: 'Nachricht bearbeiten',
    linkInfo: 'Achtung: Wenn ein Link eingestellt wird, dann kommt der bzw. die Nutzer*in nicht auf die Detailsicht in der Melde-App, sondern wird zu dem angegebenen Link weitergeleitet.',
    textInfo: 'Dieser Text wird in der Detailansicht der Nachricht in der Melde-App angezeigt.'
  },
  excelExportModal: {
    title: 'Excel Export',
    status: 'Status',
    category: 'Kategorie',
    clerk: 'Sachbearbeiter*innen',
    onlyArchivedNotices: 'Nur archivierte Meldungen exportieren',
    exportButton: 'Exportieren'
  },
  rangeDatePicker: {
    startDate: 'Startdatum',
    endDate: 'Enddatum',
    errStartDate: 'Das eingegebene Startdatum ist ungültig.',
    errEndDate: 'Das eingegebene Enddatum ist ungültig.',
    errEndDateBefore: 'Das Enddatum liegt vor dem Startdatum.'
  },
  statusHistorieModal: {
    title: 'Statushistorie',
    closeButton: 'Schließen'
  },
  noticeDetailChangeModal: {
    title: 'Änderungen übernehmen',
    message: 'Möchten Sie die Änderungen übernehmen?',
    acceptButton: 'Ja',
    cancelButton: 'Abbrechen'
  },
  adminDataModal: {
    title: 'Interne Informationen',
    saveButton: 'Speichern',
    cancelButton: 'Abbrechen',
    startTime: 'Startzeit',
    endTime: 'Endzeit',
    internalNotice: 'interne Anmerkung'
  },
  calendar: {
    weekdays: 'Mo;Di;Mi;Do;Fr;Sa;So',
    months: 'Jan;Feb;März;Apr;Mai;Jun;Jul;Aug;Sep;Okt;Nov;Dez',
    monthsFullName: 'Jänner;Februar;März;April;Mai;Juni;Juli;August;September;Oktober;November;Dezember',
    weekLabel: 'Woche'
  },
  statistics: {
    categoryStats: {
      title: 'Anzahl der Meldungen nach Kategorien',
    },
    statusStats : {
      title: 'Anzahl der Meldungen nach Status',
    },
    generalStats : {
      title: 'Übersicht',
      total: 'bisher gemeldete Schäden',
      openNotices: 'offene Meldungen',
      totalUsers: 'Nutzer*innen'
    },
    yearlyStats : {
      title: 'Meldungen nach Jahren',
      month: {
        1: 'Jan',
        2: 'Feb',
        3: 'März',
        4: 'Apr',
        5: 'Mai',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Okt',
        11: 'Nov',
        12: 'Dez'
      }
    },
    filters: {
      status: 'Status',
      archived: 'archivierte Meldungen'
    }
  },
  settings: {
    other: {
      collapse: 'Sonstige Einstellungen'
    }
  },
  users: {
    title: 'Benutzer*innen Übersicht',
    search: {
      title:  'Nach Benutzer*in suchen',
      placeholder: 'Hier tippen um Suche zu starten...'
    },
    roles : {
      info: 'Rollen-Info',
      role: 'Rolle',
      description: 'Beschreibung',
      User: {
        description: 'Jede Person, die sich bei der App anmeldet.'
      },
      Admin: {
        description: 'Alle Personen, die auf alle Funktionen des Admin-Tools Zugriff haben.'
      },
      Employee: {
        description: 'Personen, die über das Admin-Tool Meldungen erstellen können.'
      },
      Clerk: {
        description: 'Sachbearbeiter*innen'
      },
      multipleRoleInfo: 'Eine Person kann mehrere Rollen haben.'
    },
    table: {
      user: 'Benutzer*in',
      email: 'E-Mail',
      ban: 'Sperren / Entsperren',
      admin: 'Admin-Rechte',
      banned: 'Gesperrt',
      notBanned: 'Nicht Gesperrt'
    }
  },
  archived: {
    title: 'Auswertungen filtern nach ...',
    all: 'Alle Meldungen',
    archived: 'Archivierte Meldungen',
    not_archived: 'Nicht archivierte Meldungen'
  },
  assigned: {
    all: 'Alle Meldungen',
    assigned: 'Zugewiesene Meldungen',
    not_assigned: 'Nicht zugewiesene Meldungen'
  }
};
